import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';  // Importar el local en español

@Pipe({
  name: 'customDatePipe'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  constructor() {
    super('es');  // Establecer el local español
    registerLocaleData(localeEs);  // Registrar los datos locales en español
  }

  override transform(value: any, args?: any): any {
    return super.transform(value, "EEEE d 'de' MMMM 'del' y  h:mm a ");
  }
}
