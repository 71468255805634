import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { TOKEN } from '../core/constant/localstorage.constant';
import { AuhtResponse } from './interfaces';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { DocumentsService } from '../core/services/documents.service';
import { PermissionGuard } from '../guards/permission.guard';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  documentUrl: string;
  documentVersion: number;
  documentId: string;
  guardVariable: boolean;
  username: string;
  constructor(
    private readonly fb: FormBuilder,
    private readonly auth: AuthService,
    private router: Router,
    private readonly documentsService: DocumentsService
  ) {}

  ngOnInit(): void {
    this.getDocument();
  }

  showPassword: boolean = false;
  pass: string = '';
  passwordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  formLogin = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  authResponse: AuhtResponse;
  login() {
    this.auth.auth(this.formLogin.value).subscribe({
      next: (res) => {
        this.auth.currentUser = res.user;
        this.auth.lastLogin = res.lastLogin;
        localStorage.setItem(TOKEN, res.accessToken);
        this.authResponse = res;
        this.router.navigate(['/dataprotection']);
        this.guardVariable = true;
        // this.username=this.authResponse.user.name;
      },
      error: (err) => {
        this.guardVariable = false;
        var textError: string;
        switch (err.status) {
          case 400:
            textError = 'Lo sentimos, ha ocurrido un error.';
            break;
          case 403:
            textError = 'Las credenciales ingresadas no son válidas.';
            break;
          case 401:
            textError = 'Las credenciales ingresadas no son válidas.';
            break;
          case 403:
            textError = 'No posee los permisos necesarios para acceder.';
            break;
          case 404:
            textError =
              'Lo sentimos, el servidor no pudo encontrar el contenido solicitado';
            break;
          case 409:
            textError = 'Lo sentimos, ha ocurrido un error interno.';
            break;
          case 500:
            textError =
              'Lo sentimos, ha ocurrido un error interno, por favor cominiquese con el administrador';
            break;
          default:
            textError =
              'Lo sentimos, ha ocurrido un error interno, por favor cominiquese con el administrador';
            break;
        }
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: textError,
        });
      },
    });
  }

  getDocument() {
    this.documentsService.getDocument().subscribe({
      next: (resp) => {
        this.documentUrl = resp.url;
        this.documentVersion = resp.version;
        this.documentId = resp._id;
      },
    });
  }
}
