import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Document as DocumentResponse} from "../interfaces/document.interface"
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  version: number;
  url: string;

  constructor(
    private http: HttpClient
  ) { }

  getDocument() {
    return this.http.get<DocumentResponse>(`${environment.apiUrl}/documents/last`);
  }

}
