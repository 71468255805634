import * as xlsx from 'xlsx';
import * as excel from 'exceljs';
import { saveAs } from 'file-saver';

import { Component, Injectable, OnInit } from '@angular/core';
import {
  NgbCalendar,
  NgbDateStruct,
  NgbDatepickerI18n,
  NgbModal,
  NgbModalConfig,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { RegistersService } from './services/registers.service';
import { Channel, PostBody, RegisterInfo } from './interfaces';
import Swal from 'sweetalert2';
import { DocumentsService } from '../core/services/documents.service';
import { User } from '../login/interfaces';
import { AuthService } from '../login/services/auth.service';
import { Router } from '@angular/router';
import Validation from '../core/validators/identification-document';
import { DomSanitizer } from '@angular/platform-browser';

const I18N_VALUES: any = {
  es: {
    // Cambiamos el código del idioma a 'es' para español
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá', 'Do'], // Días de la semana en español
    months: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic',
    ], // Meses en español
    weekLabel: 'sem',
  },
  // otros idiomas que podrías soportar
};

// Define un servicio que almacene el idioma. Probablemente ya tienes uno si tu aplicación está internacionalizada. También podrías usar el valor LOCALE_ID de Angular
@Injectable()
export class I18n {
  language = 'es'; // Cambiamos el idioma a 'es' para español
}

// Define un servicio personalizado que proporcione las traducciones de los meses y los días de la semana
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  override getWeekLabel(): string {
    return I18N_VALUES[this._i18n.language].weekLabel;
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-dataprotection',
  templateUrl: './dataprotection.component.html',
  styleUrls: ['./dataprotection.component.css'],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ], // define el proveedor personalizado de NgbDatepickerI18n
})
export class DataprotectionComponent implements OnInit {
  modalRef: NgbModalRef;
  registerinfo: RegisterInfo | undefined;
  servicios: Channel[];
  registerBody: PostBody;
  detailExist = false;
  documentUrl: any;
  documentId: string;
  fullName: string;
  documentVersion: number;
  servicesSelected: any;
  maxChars = 20;
  window: any;
  currentUser: User;

  constructor(
    config: NgbModalConfig,
    private modal: NgbModal,
    private readonly fb: FormBuilder,
    private readonly regService: RegistersService,
    private readonly documentsService: DocumentsService,
    public readonly authService: AuthService,
    private readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private calendar: NgbCalendar
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }
  model: NgbDateStruct | null;
  minDate: NgbDateStruct = { year: 1920, month: 1, day: 1 };
  maxDate: NgbDateStruct = { year: 2025, month: 12, day: 31 };

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.getChannels();
    this.getDocument();
  }

  clearModel() {
    this.model = null;
  }
  formSearch = this.fb.group({
    identification: ['', Validators.required],
    birthDate: ['', Validators.required],
  });
  changeBirtDate() {
    const year = this.model!.year;
    const month = this.model!.month.toString().padStart(2, '0'); // Agrega un cero delante si el número es menor que 10
    const day = this.model!.day.toString().padStart(2, '0'); // Agrega un cero delante si el número es menor que 10

    this.formSearch.controls['birthDate'].setValue(`${year}-${month}-${day}`);
  }
  channelForm = this.fb.group({
    channel: ['Seleccionar operación...'],
  });
  termsAndConditions = this.fb.group({
    terms: [false, Validators.required],
  });

  emailForm = this.fb.group({
    email: [{ value: '' }, [Validators.required, Validators.email]],
  });

  getDocument() {
    this.documentsService.getDocument().subscribe({
      next: (resp) => {
        this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          resp.url
        );
        this.documentVersion = resp.version;
        this.documentId = resp._id;
      },
    });
  }

  getChannels() {
    this.regService.getChannels().subscribe({
      next: (res) => {
        this.servicios = res;
      },
      error: (err) => {},
    });
  }
  /* 
  changeEmailInputStatus() {
    this.emailForm.controls['email'].disabled
      ? this.emailForm.controls['email'].enable()
      : this.emailForm.controls['email'].disable();
  } */

  getEmailInputStatus() {
    return this.emailForm.controls['email'].enabled;
  }

  closeModal() {
    this.modalRef.dismiss();
  }

  openModal(contenido: any) {
    this.modalRef = this.modal.open(contenido, { size: 'xlg', centered: true });
  }
  messageAux = false;
  openModalLg(contenido: any) {
    if (
      this.channelForm.controls['channel'].value === 'Seleccionar operación...'
    ) {
      this.messageAux = true;
      return;
    }
    this.messageAux = false;

    this.modalRef = this.modal.open(contenido, { size: 'lg', centered: true });
  }

  search(sucees: any, next: any) {
    this.regService
      .search(this.formSearch.value, this.documentVersion)
      .subscribe({
        next: (res) => {
          this.emailForm.controls['email'].setValue(res.register.email);
          if (res.isRegistered) {
            if (res.register.hasAgreed) {
              this.openModal(sucees);
            } else {
              this.openModal(next);
            }
          }

          this.registerinfo = res;
          this.detailExist = true;
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: err.error.message,
            confirmButtonText: 'Aceptar',
            confirmButtonColor: 'red',
          });
        },
      });
  }

  verifyCheck(data: boolean, warning: any, email: any) {
    this.closeModal();
    const check = data;
    this.registerBody = {
      identification: this.registerinfo!.register.identification,
      fullName: this.registerinfo!.register.fullName,
      email: this.registerinfo!.register.email,
      notificationEmail: this.registerinfo!.register.email,
      phone: this.registerinfo!.register.phone,
      channelId: this.servicesSelected._id || null,
      hasAgreed: true,
      terms: this.documentId,
    };
    if (!check) {
      this.registerBody.hasAgreed = false;
      this.openModal(warning);
    } else {
      this.emailForm.controls['email'].setValue(
        this.registerinfo!.register.email
      );

      this.openModal(email);
    }
  }

  sendEmail(success: any) {
    this.modalRef.dismiss();
    //this.registerBody.notificationEmail= this.emailForm.controls['email'].value;
    this.updateReg(success);
  }

  updateReg(modal: any) {
    if (this.registerBody.hasAgreed) {
      this.registerBody.notificationEmail =
        this.emailForm.controls['email'].value;
      this.registerBody.email = this.emailForm.controls['email'].value;
    } else {
      if (
        this.registerBody.notificationEmail === null ||
        this.registerBody.notificationEmail.length === 0
      ) {
        this.registerBody.notificationEmail = 'notieneemail@gmail.com';
      }
      if (
        this.registerBody.email === null ||
        this.registerBody.email.length === 0
      ) {
        this.registerBody.email = 'notieneemail@gmail.com';
      }
    }
    this.regService.updateRegister(this.registerBody).subscribe({
      next: (res) => {
        this.openModal(modal);
      },
    });
  }

  setData() {
    this.channelForm.reset();
    this.channelForm.controls['channel'].setValue('Seleccionar operación...');
    this.modalRef.close();
    this.detailExist = false;
    this.servicesSelected = null;
    this.clearModel();
    setTimeout(() => {
      this.registerinfo = undefined;
    }, 1000);
    this.formSearch.reset();

    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    //   this.router.navigate([this.router.url]);
    // });
  }

  takeServicios() {
    const serviceName = this.channelForm.controls['channel'].value!;
    this.servicios.forEach((element) => {
      if (element.name === serviceName) {
        this.servicesSelected = element;
        return;
      }
    });
  }

  logout() {
    Swal.fire({
      title: 'Cerrar sesión',
      text: '¿Estás seguro de que desea cerrar sesión?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, cerrar sesión',
      iconColor: 'red',
      confirmButtonColor: 'red',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/login');
        localStorage.clear();
      }
    });
  }

  generateReport() {
    this.regService.getReport(this.documentVersion).subscribe({
      next: (res) => {
        const workbook = new excel.Workbook();
        const worksheet = workbook.addWorksheet('Reporte');

        // Combinar celdas para el título
        worksheet.mergeCells('A1:L1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = 'REPORTE DE ACEPTACIÓN DE TÉRMINOS Y CONDICIONES';
        titleCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8f8f8f' },
        };
        titleCell.font = { bold: true, size: 14, color: { argb: 'ffffff' } };
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.addRow([]);

        // Combinar celdas para la fecha
        worksheet.mergeCells('A3:B3');
        const subTitleCell = worksheet.getCell('A3');
        subTitleCell.value = 'Fecha de creación: ';
        subTitleCell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '8f8f8f' },
        };
        subTitleCell.font = { bold: true, color: { argb: 'ffffff' } };
        subTitleCell.alignment = { vertical: 'middle', horizontal: 'right' };

        const currentDate = new Date(res.date).toLocaleDateString('es-EC');
        const currentTime = new Date(res.date).toLocaleTimeString('es-EC');

        worksheet.mergeCells('C3:D3');
        const dateCell = worksheet.getCell('C3');
        dateCell.value = `${currentDate} ${currentTime}`;

        worksheet.addRow([]);

        const headerRow = worksheet.addRow([
          '#',
          'Identificación',
          'Cliente',
          'Acepta',
          'Correo',
          'Correo de notificación',
          'Teléfono',
          'Canal',
          'Versión de acuerdo',
          'Usuario',
          'Fecha de creación',
          'Fecha de actualización',
        ]);

        headerRow.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '8f8f8f' },
          };

          cell.font = { bold: true, color: { argb: 'ffffff' } };
        });

        res.results.forEach((element, index) => {
          worksheet.addRow([
            index + 1,
            element.identification,
            element.fullName,
            element.hasAgreed ? 'Sí' : 'No',
            element.email || 'No posee',
            element.notificationEmail || 'No posee',
            element.phone ? element.phone.substring(0, 10) : 'No posee',
            element.channel.name,
            element.termsVersion,
            element.user.name,
            element.creationDate,
            element.updateDate,
          ]);
        });

        worksheet.getColumn('B').width = 15;
        worksheet.getColumn('C').width = 60;
        worksheet.getColumn('D').width = 10;
        worksheet.getColumn('E').width = 30;
        worksheet.getColumn('F').width = 30;
        worksheet.getColumn('G').width = 15;
        worksheet.getColumn('H').width = 25;
        worksheet.getColumn('I').width = 15;
        worksheet.getColumn('J').width = 25;
        worksheet.getColumn('K').width = 20;
        worksheet.getColumn('L').width = 20;

        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const filename = `Reporte_${currentDate}_${currentTime}.xlsx`;
          saveAs(blob, filename);
        });
        // const excelData = res.results;

        // const workbook = xlsx.utils.book_new();
        // const worksheet = xlsx.utils.json_to_sheet(excelData);
        // xlsx.utils.book_append_sheet(workbook, worksheet, 'Reporte');

        // xlsx.writeFile(workbook, 'Reporte.xlsx');
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
