import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DataprotectionComponent } from './dataprotection/dataprotection.component';
import { NgbModule, NgbDatepickerI18n, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { SpinnerInterceptor } from './shared/interceptors/spinner/spinner.interceptor';
import { SpinnerComponent } from './shared/interceptors/spinner/spinner.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BnNgIdleService } from 'bn-ng-idle';
import { CustomNgbDatepickerI18n } from './core/helpers/CustomNgbDatepickerI18n';
import { CustomDatePipe } from './core/custom-date.pipe.';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DataprotectionComponent,
    SpinnerComponent,
    CustomDatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    PdfViewerModule,
    NgbModule
  ],
  providers: [
    NgbDatepickerConfig,
    BnNgIdleService,
    {
      provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
