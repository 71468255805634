import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  RegisterInfo,
  Registers,
  PostBody,
  RegisterPostResponse,
  Channel,
} from '../interfaces';
import { ReportResponse } from '../interfaces/report-response.interface';

@Injectable({
  providedIn: 'root',
})
export class RegistersService {
  constructor(private readonly http: HttpClient) {}

  search(register: Registers, version: number) {
    return this.http.get<RegisterInfo>(
      `${environment.apiUrl}/registers/identification/${register.identification}/${version}/${register.birthDate}`
    );
  }

  updateRegister(body: PostBody) {
    return this.http.post<RegisterPostResponse>( // Cambiar el tipo de respuesta -> pendiente
      `${environment.apiUrl}/registers`,
      body
    );
  }

  getChannels() {
    return this.http.get<Channel[]>(`${environment.apiUrl}/channels`);
  }

  getReport(version: number) {
    return this.http.get<ReportResponse>(
      `${environment.apiUrl}/registers/version/${version}`
    );
  }
}
