import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuhtResponse, Auth, User } from '../interfaces';
import { environment } from 'src/environments/environment';
import { catchError, map, of, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser: User;
  lastLogin: string;
  constructor(
    private readonly http: HttpClient,
    private readonly router: Router
  ) {}

  auth(auth: Auth) {
    return this.http.post<AuhtResponse>(
      `${environment.apiUrl}/auth/login-financial`,
      auth
    );
  }

  renew() {
    return this.http.get<AuhtResponse>(`${environment.apiUrl}/auth/renew`).pipe(
      tap((res) => {
        localStorage.setItem('token', res.accessToken);
        this.currentUser = res.user;
        this.lastLogin = res.lastLogin;
      }),
      map((res) => true),
      catchError(() => {
        localStorage.removeItem('token');
        return of(false);
      })
    );
  }
}
