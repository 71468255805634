import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { TOKEN } from "../constant/localstorage.constant";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {


    const token = localStorage.getItem(TOKEN) || "";
   
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      "ngrok-skip-browser-warning": "true",
      "Access-Control-Allow-Origin":"*"
    });
    
    const requestClone = request.clone({ headers });
    
    return next.handle(requestClone);
  }}