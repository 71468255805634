<header>
  <div class="headerbox">
    <img src="../../assets/images/chibuleo.svg" class="img-logo" />
  </div>
  <div class="headerbox">
    <img src="../../assets/images/ImageSlogan1-cp.png" class="img-slogan" />
  </div>
</header>

<body>
  <div class="screen">
    <div class="box">
      <img
        src="../../assets/images/WelcomeTextLogo-mini.svg"
        class="img-logo-mobile"
      />
      <p class="bienvenida">¡Bienvenidos!</p>
      <img
        src="../../assets/images/imagen-bienvenidos.svg"
        class="img-bienvenida"
      />
      <p class="text1">
        Tu seguridad y privacidad es nuestra prioridad.<br />
        Realiza tus transacciones de una manera mucho más simple, rápida y
        efectiva.
      </p>

      <p class="text-mini">
        <strong> Ingresar a tu cuenta para continuar </strong>
      </p>
    </div>

    <div class="div-mobile" [formGroup]="formLogin">
      <div class="input-container">
        <input
          type="text"
          placeholder="Ingresar tu nombre de usuario"
          formControlName="username"
        />
        <label class="input-label">Usuario</label>
        <i class="icon-security" style="cursor: pointer; cursor: hand"></i>
      </div>
      <div class="input-container">
        <input
          type="password"
          placeholder="Ingresar tu contraseña"
          formControlName="password"
        />
        <label class="input-label">Contraseña</label>
        <i class="icon-view" style="cursor: pointer; cursor: hand"></i>
      </div>

      <div class="div-button-mobile">
        <button
          (click)="login()"
          style="cursor: pointer; cursor: hand"
          [disabled]="formLogin.invalid"
        >
          Aceptar
        </button>
      </div>
    </div>

    <div class="box2" [formGroup]="formLogin">
      <div class="card">
        <!-- <div class="card-body"> -->
        <div class="input-container">
          <input
            type="text"
            placeholder="Ingresar tu nombre de usuario"
            formControlName="username"
          />
          <label class="input-label">Usuario</label>
          <i class="icon-security" style="cursor: pointer; cursor: hand"></i>
        </div>

        <div class="input-container">
          <input
            type="{{ showPassword ? 'text' : 'password' }}"
            placeholder="Ingresar tu contraseña"
            formControlName="password"
          />
          <label class="input-label">Contraseña</label>
          <i
            class="icon-view"
            style="cursor: pointer; cursor: hand"
            (click)="passwordVisibility()"
          ></i>
        </div>

        <div class="div-button">
          <button
            (click)="login()"
            style="cursor: pointer; cursor: hand"
            [disabled]="formLogin.invalid"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</body>

<footer>
  <div class="footerbox">
    <a
      [href]="documentUrl"
      target="_blank"
      style="cursor: pointer; cursor: hand; font-weight: bold"
      class="a-footer"
    >
      Términos de uso</a
    >&nbsp;
    <span
      style="
        font-family: Samsung;
        font-size: x-small;
        font-weight: lighter;
        margin-top: 3px;
      "
      >2023&nbsp;</span
    >
    COAC. Chibuleo. Todos los derechos reservados
  </div>
  <div class="footerbox">
    <a>Encuéntranos |&nbsp;</a>
    <a> Contáctanos |&nbsp;</a>
    <a> Ayuda </a>
  </div>
</footer>
