<ng-template #contenidoEmail let-modal>
  <form [formGroup]="emailForm">
    <div class="modal-body">
      <div class="modal-email">
        <p class="p-modal">
          El documento de aceptación será enviado al siguiente correo
        </p>

        <div class="input-container-email">
          <a style="text-align: left">Correo Actual</a>
        </div>
        <div class="input-container-email">
          <input
            type="email"
            formControlName="email"
            [ngClass]="{ 'input-background-enabled': getEmailInputStatus() }"
          />
        </div>
        <div class="error-container" style="margin-top: 1rem">
          <span
            class="text-danger"
            *ngIf="
              emailForm.get('email')?.hasError('required') &&
              emailForm.get('email')?.touched
            "
          >
            El email <strong>es requerido.</strong>
          </span>

          <span
            class="text-danger"
            *ngIf="
              emailForm.get('email')?.hasError('email') &&
              emailForm.get('email')?.touched
            "
          >
            El email
            <strong> no es valido.</strong>
          </span>
        </div>
        <div class="div-btn-email">
          <button
            class="b-modal-red-email"
            (click)="sendEmail(contenidoExito)"
            [disabled]="emailForm.invalid"
          >
            Enviar
          </button>
          <button class="b-modal-grey-email" (click)="modal.dismiss()">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #contenidoExito let-modal>
  <div class="modal-body">
    <div class="screen-modal">
      <div class="i-modal">
        <img src="../../assets/images/Check.svg" class="img-check" />
      </div>
      <div class="container-username">
        <img src="../../assets/images/person-outline.svg" class="img-user" />
        <a class="a-modal-exito" style="color: #535353">{{
          registerinfo!.register.fullName
        }}</a>
      </div>
      <p class="p-modal">
        Autorizaste correctamente el tratamiento de tus datos personales
      </p>
      <div class="div-btn">
        <button class="b-modal-red" (click)="setData()">Aceptar</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contenidoExitoNoAcepto let-modal>
  <div class="modal-body">
    <div class="screen-modal">
      <div class="i-modal">
        <img src="../../assets/images/AlertIcon.svg" class="img-check" />
      </div>
      <div class="container-username">
        <!-- <img src="../../assets/images/person-outline.svg" class="img-user" /> -->
        <a class="a-modal-exito" style="color: #535353">{{
          registerinfo!.register.fullName
        }}</a>
      </div>
      <p class="p-modal">
        No autorizaste el tratamiento de tus datos personales
      </p>
      <div class="div-btn">
        <button class="b-modal-red" (click)="setData()">Aceptar</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contenidoWarning let-modal>
  <div class="modal-body">
    <div class="screen-modal">
      <div class="i-modal">
        <img src="../../assets/images/AlertIcon.svg" class="img-check" />
      </div>
      <p class="p-modal">
        ¿Estás seguro que desea continuar sin aceptar el acuerdo de protección
        de datos?
      </p>

      <div class="div-btn">
        <button class="b-modal-red" (click)="sendEmail(contenidoExitoNoAcepto)">
          Si, seguro
        </button>
        <button class="b-modal-grey" (click)="closeModal()">
          No, regresar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contenidoContinue let-modal>
  <div class="modal-body">
    <div class="screen-modal">
      <div class="i-modal">
        <img src="../../assets/images/AlertIcon.svg" class="img-check" />
      </div>
      <!-- <img src="../../assets/images/person-outline.svg" class="img-user" /> -->

      <div class="container-username">
        <a class="a-modal" style="color: #535353">{{
          registerinfo!.register.fullName
        }}</a>
      </div>
      <p class="p-modal">
        Previamente NO autorizaste el tratamiento de tus datos personales, si
        deseas hacerlo clic en continuar
      </p>

      <div class="div-btn">
        <button class="b-modal-red" (click)="modal.dismiss()">Continuar</button>
        <button class="b-modal-grey" (click)="closeModal()">
          No, regresar
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #document let-modal>
  <div
    class="modal-header"
    style="display: flex; flex-direction: row; justify-content: flex-end"
  >
    <button
      type="button"
      class="btn btn-danger"
      style="background-color: red"
      (click)="closeModal()"
    >
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <form [formGroup]="emailForm">
    <div class="modal-body" style="text-align: center">
      <!-- <embed [src]="documentUrl" type="application/pdf" width="100%" height="600px" /> -->
      <div class="body" style="height: 500px; overflow: auto">
        <div
          class="title"
          style="font-size: 20px; color: red; font-weight: 600"
        >
          Autorización para tratamiento de datos personales
        </div>
        <div
          class="text"
          style="text-align: justify; padding: 1rem 1rem; font-weight: 500"
        >
          <p style="text-align: justify">
            Autorizo libre, voluntaria, previa, explícita, informada e
            inequívocamente a la Cooperativa de Ahorro y Crédito Chibuleo Ltda.,
            (en adelante la COOPERATIVA) para que realice el tratamiento de mis
            datos personales, crediticios y/o datos no públicos, o los de mi
            representada según fuera el caso (en adelante DATOS o INFORMACIÓN),
            incluyendo aquellos que entrego, haya entregado o pueda entregar
            posteriormente bien sea de manera personal o por intermedio de
            terceros que cuenten con mi suficiente autorización, de igual modo,
            los que la COOPERATIVA llegue a tener o haya tenido acceso ya sea a
            través de canales físicos, virtuales, electrónicos,
            electromecánicos, telefónicos, correos electrónicos, de formularios
            virtuales y/o cookies, sean estos propios o de redes asociadas. Por
            lo mencionado, acepto y autorizo el tratamiento inclusive de mis
            datos sensibles, tales como biométricos, de salud, crediticios,
            transaccionales, datos sujetos a sigilo o reserva entre otros.
          </p>
          <p style="text-align: justify">
            El tratamiento de DATOS tendrá las siguientes finalidades: elaborar
            y/o segmentar perfiles, realizar análisis crediticios incluso
            mediante técnicas automáticas, preparar y/o emprender campañas
            informativas con enfoque de responsabilidad social, desarrollar
            acciones comerciales de productos y servicios financieros y/o
            comerciales, realizar nuevos prototipos, versiones o actualizaciones
            de canales electrónicos y/o comercializar y promocionar (incluyendo
            el envío de publicidad por cualquier medio) diferentes productos y
            servicios financieros y/o comerciales.
          </p>
          <p style="text-align: justify">
            Para efectos de esta autorización se entiende por tratamiento a
            cualquier operación o conjunto de operaciones realizadas sobre mis
            DATOS ya sea por procedimientos técnicos de carácter automatizado,
            parcialmente automatizado o no automatizado, tales como: la
            recogida, recopilación, obtención, registro, organización,
            estructuración, evaluación, análisis, conservación, custodia,
            adaptación, modificación, eliminación, indexación, extracción,
            consulta, elaboración, utilización, procesamiento, consolidación,
            anonimización, seudonimización, posesión, aprovechamiento,
            rentabilización, distribución, cesión, comunicación, disposición,
            divulgación o transferencia o cualquier otra forma de habilitación
            de acceso, cotejo, interconexión, limitación, supresión, destrucción
            y en general cualquier uso de DATOS.
          </p>
          <p style="text-align: justify">
            De la misma manera autorizo expresamente a la COOPERATIVA tratar,
            mantener, conservar y almacenar mis DATOS o los de mi representada,
            lo que fuera del caso, establecidos por la ley, con el objeto de que
            la COOPERATIVA sus subsidiarias y/o terceros autorizados de manera
            individual o conjunta puedan prestar servicios, elaborar y segmentar
            perfiles, realizar análisis crediticios incluso mediante técnicas
            automáticas que utilicen mis datos actuales, históricos y
            estadísticos, para la valoración de riesgos, mercadeo, confección y
            análisis de nuevas propuestas comerciales de servicios de cualquier
            tipo, inclusive como elemento de análisis en etapas
            pre-contractuales, contractuales y postcontractuales, con el fin de
            remitirme comunicaciones comerciales y/o contactarme por cualquier
            medio para la oferta, promoción y contratación de productos y/o
            servicios que brinde la institución.
          </p>
          <p style="text-align: justify">
            Para cumplir con las finalidades previamente descritas, mis DATOS
            y/o los de mi representada, según fuera el caso, podrán consultarse,
            compartirse, transferirse y/o comunicarse a sus proveedores,
            subsidiarias, sociedades afiliadas, relacionadas y/o auxiliares,
            empresas encargadas del tratamiento de datos y/o sociedades con las
            que la COOPERATIVA mantenga relación a nivel nacional o
            internacional. Para el efecto, la COOPERATIVA adoptará las medidas
            técnicas y organizativas aplicables para cumplir con la
            confidencialidad y seguridad correspondientes. No se requerirá de mi
            autorización para la entrega de mis datos cuando sea solicitado por
            autoridades administrativas y/o judiciales o para dar cumplimiento
            con la normativa legal vigente.
          </p>
          <p style="text-align: justify">
            De igual forma, autorizo y/o faculto a la COOPERATIVA, a cualquier
            cesionario o endosatario para que, cuantas veces sean necesarias,
            obtenga de y/o valide con cualquier fuente de información,
            incluyendo al Registro Civil, la Central de Riesgos, los burós de
            crédito, y demás fuentes legales de información autorizados para
            operar en el país, las personas naturales y los establecimientos de
            comercio, instituciones financieras de crédito, de cobranza,
            compañías emisoras o administradoras de tarjetas de crédito y
            cualquier otra entidad, mis referencias personales y/o patrimoniales
            del pasado, del presente y las que se generen en el futuro, sea como
            deudor principal, codeudor o garante, así como sobre mi
            comportamiento crediticio. En caso de que la COOPERATIVA ceda o
            transfiera cartera adeudada por mí, el cesionario o adquiriente de
            la misma queda desde ahora expresamente facultado para realizar las
            mismas actividades establecidas en este acápite.
          </p>
          <p style="text-align: justify">
            Como socio y/o cliente garantizo la veracidad de los DATOS
            entregados a la COOPERATIVA, así también, se reconoce que tengo
            derecho a solicitar el acceso, rectificación, actualización,
            revisión, portabilidad, cancelación, eliminación, oposición y
            limitación del tratamiento de estos. Cualquier comunicación
            relacionada con mis derechos, podré dirigirla al funcionario
            designado por la COOPERATIVA a la dirección de correo electrónicos
            <span style="color: red">
              protecciondatospersonales@chibuleo.com</span
            >
            o mediante comunicación escrita entregada en las oficinas de la
            COOPERATIVA ubicadas a nivel nacional. Me comprometo a comunicar
            inmediatamente a la COOPERATIVA sobre cualquier variación de mis
            DATOS. La COOPERATIVA no será responsable de los resultados
            derivados del procesamiento de información que se realicen como
            consecuencia de datos erróneos, desactualizados o inexactos. La
            COOPERATIVA se reserva el derecho a no prestar los servicios
            correspondientes en caso de que se proporcione datos erróneos,
            desactualizados o inexactos e incluso la Cooperativa queda
            autorizada a cerrar la cuenta que como socio o cliente mantengo, por
            tanto, reconozco el derecho de la COOPERATIVA a limitar o terminar
            la relación comercial también en el caso de que esta autorización
            sea revocada. Autorizo a la COOPERATIVA y sus subsidiarias o
            afiliadas puedan contactarme en cualquier tiempo a través de SMS,
            correo electrónico, llamadas telefónicas por parte de operadores
            humanos o virtuales, aplicaciones para teléfonos móviles, sistemas
            de mensajería, notificaciones Push, en general por cualquier medio o
            mecanismo de comunicación que la COOPERATIVA tenga disponible o
            habilitare en el futuro, para cualquiera de los fines indicados en
            este documento.
          </p>
          <p style="text-align: justify">
            Declaro entender que la INFORMACIÓN recopilada por la COOPERATIVA es
            necesaria para la correcta operación de los canales; lo que incluye
            la atención y ejecución de los requerimientos de productos,
            servicios o transacciones que se realicen a través de ellos y la
            prestación íntegra y oportuna de los servicios adquiridos y que
            brinda la Cooperativa.
          </p>
          <p style="text-align: justify">
            Por lo expuesto dejo constancia de mi aceptación, sin reserva ni
            condicionamiento alguno, de las declaraciones y compromisos que aquí
            contienen. En particular, acepto y apruebo de forma expresa, libre,
            específica, inequívoca e informada el manejo de mis datos por parte
            de la COOPERATIVA en la forma y con los alcances que se detallan en
            este documento. Manifiesto que con la lectura de este documento he
            sido informado sobre el Tratamiento de Datos Personales, y otorgo mi
            autorización de forma libre, voluntaria y verídica, por intermedio
            del presente documento.
          </p>
        </div>
      </div>
      <form
        class="form-checked-aux"
        [formGroup]="termsAndConditions"
        style="width: 100%"
      ></form>

      <div
        class="button-container"
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 1rem;
        "
      >
        <button
          class="button-aux disabled-button"
          (click)="verifyCheck(false, contenidoWarning, contenidoEmail)"
          style="cursor: pointer; cursor: hand"
        >
          Rechazar
        </button>
        <button
          class="button-aux"
          (click)="verifyCheck(true, contenidoWarning, contenidoEmail)"
          style="cursor: pointer; cursor: hand"
          [disabled]="
            this.channelForm.controls['channel'].value ===
            'Seleccionar operación...'
          "
          [ngClass]="{
            'disabled-button':
              this.channelForm.controls['channel'].value ===
              'Seleccionar operación...'
          }"
        >
          Aceptar
        </button>
      </div>
    </div>
  </form>
</ng-template>
<header>
  <div class="header-logout">
    <!-- <button type="button" class="botonReporte" (click)="generateReport()">
      Reporte
    </button> -->
    <p style="font-size: small; font-weight: bold">
      Ultimo inicio de sesión: {{ authService.lastLogin | customDatePipe }}
    </p>
    <p style="font-size: small; font-weight: bold">{{ currentUser.name }}</p>
    <img
      src="../../assets/images/CerrarSesión.svg"
      style="cursor: pointer; cursor: hand; width: 7.5rem"
      (click)="logout()"
    />
  </div>

  <div class="header-logos">
    <div class="headerbox">
      <img src="../../assets/images/chibuleo.svg" class="img-logo" />
    </div>
    <div class="headerbox">
      <img src="../../assets/images/slogan.svg" class="img-slogan" />
    </div>
  </div>
</header>

<body>
  <div class="screen">
    <div class="box">
      <div class="header-mobile">
        <button type="button" (click)="generateReport()">Reporte</button>
        <p style="font-size: small; font-weight: bold">
          Ultimo inicio de sesión: {{ authService.lastLogin | customDatePipe }}
        </p>
        <p style="font-size: small; font-weight: bold">
          {{ currentUser.name }}
        </p>
        <img
          src="../../assets/images/CerrarSesión.svg"
          style="cursor: pointer; cursor: hand; width: 7.5rem"
          (click)="logout()"
        />
      </div>

      <img
        src="../../assets/images/WelcomeTextLogo-mini.svg"
        class="img-logo-mobile"
      />

      <p class="bienvenida">¡Bienvenidos!</p>
      <img
        src="../../assets/images/imagen-bienvenidos.svg"
        class="img-bienvenida"
      />

      <p class="text1">
        Tu seguridad y privacidad es nuestra prioridad.<br />
        Realiza tus transacciones de una manera mucho más simple, rápida y
        efectiva.
      </p>
    </div>

    <div class="box2 animate__animated animate__fadeIn">
      <!-- <img src="../../assets/images/Slogan.svg" class="img-slogan" /> -->
      <div class="card">
        <!-- <div class="card-body"> -->
        <div
          class="input-container"
          *ngIf="!detailExist"
          [formGroup]="formSearch"
        >
          <input
            type="text"
            placeholder=""
            formControlName="identification"
            maxlength="{{ maxChars }}"
          />
          <label class="input-label">Ingresar Cédula</label>
        </div>
        <div class="error-container" *ngIf="!detailExist">
          <span
            class="text-danger"
            *ngIf="
              formSearch.get('identification')?.hasError('required') &&
              formSearch.get('identification')?.touched
            "
          >
            El documento de identificación <strong>es requerido.</strong>
          </span>

          <span
            class="text-danger"
            *ngIf="
              formSearch.hasError('isInvalidCI') &&
              formSearch.get('identification')?.touched
            "
          >
            El documento de identificación
            <strong> no es valido.</strong>
          </span>
        </div>

        <div style="width: 75%" *ngIf="!detailExist">
          <div class="input-group">
            <input
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp"
              [(ngModel)]="model"
              ngbDatepicker
              #d="ngbDatepicker"
              (ngModelChange)="changeBirtDate()"
              [minDate]="minDate"
              [maxDate]="maxDate"
            />

            <button
              class="btn btn-outline-secondary bi bi-calendar3"
              (click)="d.toggle()"
              type="button"
            >
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>

        <button
          class="button-aux"
          (click)="search(contenidoExito, contenidoContinue)"
          style="cursor: pointer; cursor: hand"
          [disabled]="
            formSearch.invalid ||
            formSearch.controls['identification'].value === null ||
            formSearch.controls['birthDate'].value === null
          "
          *ngIf="!detailExist"
          [ngClass]="{
            'disabled-button':
              formSearch.invalid ||
              formSearch.controls['identification'].value === null ||
              formSearch.controls['birthDate'].value === null
          }"
        >
          Buscar
        </button>

        <div class="dropdown-div" [formGroup]="channelForm">
          <select
            class="form-select"
            *ngIf="detailExist"
            (change)="takeServicios()"
            formControlName="channel"
          >
            <option class="dropdown-item">Seleccionar operación...</option>
            <option *ngFor="let servicio of servicios">
              {{ servicio.name }}
            </option>
          </select>
        </div>

        <div
          id=" div-info-aux "
          class="div-info-aux animate__animated animate__fadeIn"
          *ngIf="detailExist"
        >
          <p class="p-aux">Socio/Cliente encontrado con esta cédula:</p>
          <img src="../../assets/images/person-outline.svg" />

          <p class="p-aux">{{ registerinfo!.register.fullName }}</p>
          <p class="p-terms-aux">
            Con la finalidad de desarrollar una mejor experiencia para los
            usuarios de la Cooperativa y poner a tu disposición nuestros
            productos y servicios, así como brindarte propuestas personalizadas
            y experiencia de alta calidad, solicitamos que leas y aceptes el
            documento de Consentimiento de Tratamiento de Datos Personales.
          </p>
          <p class="p-authorization-aux">
            Autoriza el tratamiento de tus datos personales
          </p>

          <a
            class="a-authorization-aux"
            (click)="openModalLg(document)"
            style="cursor: pointer; cursor: hand"
            style="
              color: #4a3aff;
              text-decoration-line: underline;
              font-size: small;
              padding-top: 1rem;
            "
            >Leer más acerca de la Autorización</a
          >
          <span class="text-danger" *ngIf="this.messageAux">
            Primero debes seleccionar una operación</span
          >
        </div>
      </div>
    </div>

    <div class="div-mobile">
      <p style="color: #525252; width: 75%; text-align: center">
        Tu seguridad y privacidad es nuestra prioridad. Realiza tus
        transacciones de una manera mucho más simple, rápida y efectiva.
      </p>

      <div class="input-container-mobile" *ngIf="!detailExist">
        <div
          class="input-container"
          *ngIf="!detailExist"
          [formGroup]="formSearch"
          style="width: 100% !important"
        >
          <input
            type="text"
            placeholder=""
            formControlName="identification"
            maxlength="{{ maxChars }}"
          />
          <label class="input-label">Ingresar Cédula</label>
        </div>
        <div
          class="error-container"
          style="margin-bottom: 1rem"
          *ngIf="!detailExist"
        >
          <span
            class="text-danger"
            *ngIf="
              formSearch.get('identification')?.hasError('required') &&
              formSearch.get('identification')?.touched
            "
          >
            El documento de identificación <strong>es requerido.</strong>
          </span>

          <span
            class="text-danger"
            *ngIf="
              formSearch.hasError('isInvalidCI') &&
              formSearch.get('identification')?.touched
            "
          >
            El documento de identificación
            <strong> no es valido.</strong>
          </span>
        </div>

        <div
          style="
            width: 75%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            width: 100% !important;
          "
          *ngIf="!detailExist"
        >
          <div class="input-group">
            <input
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp"
              [(ngModel)]="model"
              ngbDatepicker
              #d="ngbDatepicker"
              (ngModelChange)="changeBirtDate()"
              [minDate]="minDate"
              [maxDate]="maxDate"
            />

            <button
              class="btn btn-outline-secondary bi bi-calendar3"
              (click)="d.toggle()"
              type="button"
            >
              <i class="fa fa-calendar"></i>
            </button>
          </div>
        </div>

        <button
          class="button-aux"
          (click)="search(contenidoExito, contenidoContinue)"
          style="cursor: pointer; cursor: hand; width: 100% !important"
          [disabled]="formSearch.invalid"
          *ngIf="!detailExist"
          [ngClass]="{
            'disabled-button': formSearch.invalid
          }"
        >
          Buscar
        </button>
      </div>
      <!-- INFO MOBILE -->
      <div class="dropdown-div" [formGroup]="channelForm">
        <select
          class="form-select"
          *ngIf="detailExist"
          (change)="takeServicios()"
          formControlName="channel"
        >
          <option class="dropdown-item">Seleccionar operación...</option>
          <option *ngFor="let servicio of servicios">
            {{ servicio.name }}
          </option>
        </select>
      </div>
      <div id="div-info-mobile" class="div-info-mobile" *ngIf="detailExist">
        <p
          style="color: #535353; text-align: left; margin: 1px; font-size: 80%"
        >
          Nombre y Apellido
        </p>

        <div class="icon-user-mobile">
          <img src="../../assets/images/person-outline.svg" />
          <p class="p-mobile-name">{{ registerinfo!.register.fullName }}</p>
        </div>

        <p class="p-mobile">Autoriza el tratamiento de tus datos personales</p>

        <a
          class="a-authorization-mobile"
          (click)="openModalLg(document)"
          style="cursor: pointer; cursor: hand"
          style="
            color: #4a3aff;
            text-decoration-line: underline;
            font-size: small;
          "
          >Leer más acerca de la Autorización</a
        >
        <span class="text-danger" *ngIf="this.messageAux">
          Primero debes seleccionar una operación</span
        >
      </div>
    </div>
  </div>
</body>

<footer>
  <div class="footerbox">
    <a
      [href]="documentUrl"
      target="_blank"
      style="cursor: pointer; cursor: hand; font-weight: bold"
      class="a-footer"
    >
      Términos de uso</a
    >&nbsp;
    <span
      style="
        font-family: Samsung;
        font-size: x-small;
        font-weight: lighter;
        margin-top: 3px;
      "
      >2023&nbsp;</span
    >
    COAC. Chibuleo. Todos los derechos reservados
  </div>
  <div class="footerbox">
    <!-- <a>Encuéntranos |&nbsp;</a>
    <a> Contáctanos |&nbsp;</a>
    <a> Ayuda </a> -->
    <a
      class="report"
      (click)="generateReport()"
      *ngIf="currentUser.role === 'ADMIN'"
      >Descargar Reporte</a
    >
  </div>
</footer>
