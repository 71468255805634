import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthService } from '../login/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WithoutSaveGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.renew().pipe(
      map((res) => !res),
      tap((res) => !res && this.router.navigate(['/', 'dataprotection']))
    );
  }
}
