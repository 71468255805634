import { Component, OnInit } from '@angular/core';
import { DocumentsService } from './core/services/documents.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Ley de protección de datos';

  constructor(private documentsService: DocumentsService, private bnIdle: BnNgIdleService, private readonly router: Router) { }


  async ngOnInit() {
    this.documentsService.getDocument().subscribe({
      next: (resp) => {
        this.documentsService.url = resp.url;
        this.documentsService.version = resp.version;
      },
      error: (error) => {
        //console.error(error)
      }
    });
    this.bnIdle.startWatching(10*60).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        if (localStorage.getItem("token")) {
          this.router.navigate(["/"])
          localStorage.clear();
          Swal.fire({
            icon: 'info',
            title: 'Sesión expirada',
            text: "Su sesión a expirado",
            confirmButtonText: 'Aceptar',
            iconColor:"red",
            confirmButtonColor:"red"
          
          });
        }

      }
    });
  }
}
