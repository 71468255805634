import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DataprotectionComponent } from './dataprotection/dataprotection.component';
import { PermissionGuard } from './guards/permission.guard';
import { WithoutSaveGuard } from './guards/without-save.guard';

const routes: Routes = [
  { path: '', component: LoginComponent, canActivate: [WithoutSaveGuard] },
  { path: 'login', component: LoginComponent, canActivate: [WithoutSaveGuard] },
  {
    path: 'dataprotection',
    component: DataprotectionComponent,
    canActivate: [PermissionGuard],
  },

  { path: '**', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
